const process = (data) => {
  if (!data) return data; // Rückgabe, wenn keine Daten vorhanden sind

  // Daten sind ein Array
  if (Array.isArray(data)) {
    return data.map((item) => {
      if (typeof item === 'string') {
        return item; // String unverändert zurückgeben
      }
      if (Array.isArray(item) || typeof item === 'object') {
        return process(item); // Rekursive Verarbeitung für Arrays und Objekte
      }
      return item;
    });
  }

  // Daten sind ein Objekt
  if (typeof data === 'object') {
    Object.keys(data).forEach((key) => {
      const item = data[key];
      if (typeof item === 'string') {
        data[key] = item; // String unverändert lassen
      } else if (Array.isArray(item) || typeof item === 'object') {
        data[key] = process(item); // Rekursive Verarbeitung für Arrays und Objekte
      }
    });
    return data;
  }

  // Daten sind ein String
  if (typeof data === 'string') {
    return data; // String unverändert zurückgeben
  }

  return data;
};

export default process;

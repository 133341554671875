import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';
import store from '@/store';
import { useAuth } from '@/store2/useAuth';
import { Mutations, Actions } from '@/store/enums/StoreEnums';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/assets',
    component: () => import('@/view/layout/DefaultLayout.vue'),
    children: [
      {
        path: '/assets',
        name: 'assets',
        component: () => import('@/view/pages/assets/Assets.vue')
      }
    ]
  },
  {
    path: '/admin',
    component: () => import('@/view/layout/DefaultLayout.vue'),
    children: [
      {
        path: '/admin',
        name: 'admin',
        component: () => import('@/view/pages/admin/Admin.vue')
      },
      {
        path: '/admin/users',
        name: 'admin-users',
        component: () => import('@/view/pages/admin/Users.vue')
      },
      {
        path: '/admin/users/:id',
        name: 'admin-user-details',
        props: true,
        component: () => import('@/view/pages/admin/user-details/UserDetails.vue')
      },
      {
        path: '/admin/terms-and-conditions',
        name: 'terms-and-conditions',
        component: () => import('@/view/pages/admin/terms-and-conditions/TermsAndConditions.vue')
      }
    ]
  },
  {
    path: '/users',
    component: () => import('@/view/layout/DefaultLayout.vue'),
    children: [
      {
        path: '/users/me',
        name: 'users-me',
        component: () => import('@/view/pages/users/profile/Profile.vue')
      }
    ]
  },
  {
    path: '/',
    component: () => import('@/view/layout/AuthLayout.vue'),
    children: [
      {
        path: '/login-admin',
        name: 'login-admin',
        component: () => import('@/view/pages/auth/LoginAdmin.vue')
      },
      {
        path: '/login',
        name: 'login',
        component: () => import('@/view/pages/auth/Login.vue')
      },
      {
        path: '/registrieren',
        name: 'register',
        props: (route) => ({ token: route.query.id }),
        component: () => import('@/view/pages/auth/Register.vue')
      },
      {
        path: '/passwort-vergessen',
        name: 'password-reset',
        component: () => import('@/view/pages/auth/PasswordReset.vue')
      },
      {
        path: '/neues-passwort',
        name: 'set-password',
        props: (route) => ({ token: route.query.id }),
        component: () => import('@/view/pages/auth/SetNewPassword.vue')
      }
    ]
  },
  {
    // the 404 route, when none of the above matches
    path: '/404',
    name: '404',
    component: () => import('@/view/pages/error/Error404.vue')
  },
  {
    // the 404 route, when none of the above matches
    path: '/500',
    name: '500',
    component: () => import('@/view/pages/error/Error500.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/404'
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

router.beforeEach(() => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;

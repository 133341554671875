import LayoutConfigTypes from '@/core/config/LayoutConfigTypes';

const config: LayoutConfigTypes = {
  themeName: 'DEVK Medien Portal',
  themeVersion: '8.1.1',
  demo: 'demo1',
  main: {
    type: 'default',
    primaryColor: '#063',
    logo: {
      dark: 'media/logos/default-dark.svg',
      light: 'media/logos/default.svg'
    }
  },
  illustrations: {
    set: 'sketchy-1'
  },
  loader: {
    logo: 'media/logos/default-dark.svg',
    display: true,
    type: 'default'
  },
  scrollTop: {
    display: true
  },
  header: {
    display: true,
    menuIcon: 'font',
    width: 'fluid',
    fixed: {
      desktop: true,
      tabletAndMobile: true
    }
  },
  toolbar: {
    display: false,
    width: 'fluid',
    fixed: {
      desktop: true,
      tabletAndMobile: true
    }
  },
  aside: {
    display: true,
    theme: 'light',
    fixed: true,
    menuIcon: 'svg',
    minimized: false,
    minimize: true,
    hoverable: true
  },
  content: {
    width: 'fluid'
  },
  footer: {
    width: 'fluid'
  }
};

export default config;

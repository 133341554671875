
import { defineComponent, nextTick, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useAuth } from '@/store2/useAuth';

import { Mutations } from '@/store/enums/StoreEnums';
import { initializeComponents } from '@/core/plugins/keenthemes';

export default defineComponent({
  name: 'app',
  setup() {
    const store = useStore();
    const auth = useAuth();

    onMounted(async () => {
      /**
       * this is to override the layout config using saved data from localStorage
       * remove this to use config only from static config (@/core/config/DefaultLayoutConfig.ts)
       */
      store.commit(Mutations.OVERRIDE_LAYOUT_CONFIG);

      // Singleton initialisierung
      // UserAuth.provide

      // init auth (sets authMode)
      await auth.init();

      nextTick(() => {
        initializeComponents();
      });
    });
  }
});

import { configure } from 'vee-validate';
import { localize } from '@vee-validate/i18n';
import de from '@vee-validate/i18n/dist/locale/de.json';

export function initVeeValidate() {
  // Updating default vee-validate configuration
  configure({
    generateMessage: localize({ de }),
    validateOnBlur: true,
    validateOnChange: true,
    validateOnInput: true,
    validateOnModelUpdate: true
  });
}

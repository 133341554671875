import router from '@/router';
import sanitizer from '@/core/helpers/sanitizer';

function push(path) {
  // push to login if 401
  router.push(path).catch((err) => {
    // Ignore the vue err regarding navigating to the page they are already on.
    // which can happen on login and illegal token
    if (err.name !== 'NavigationDuplicated' && !err.message.includes('Avoided redundant navigation to current location')) {
      // But print any other errors to the console
      // console.log(err);
    }
  });
}

const santizizeInterceptor = (response) => {
  if (response.data) {
    response.data = sanitizer(response.data);
  }
  return response;
};

const errorInterceptor = (err) => {
  const errorCode = err.response.status;

  /**
   * reformat error page
   */
  if (errorCode === 400) {
    return Promise.reject({
      status: err.response.status,
      message: err.response.data.message,
      type: err.response.data.type
    });
  }

  /**
   * redirect to 404 page
   */

  if (errorCode === 404) {
    alert('ERROR: ' + err.request.responseURL + ' not found');
  }

  /**
   * Logout
   * TODO: Logout
   * @type {string}
   */
  if (errorCode === 401) {
    push('/login');
  }

  /**
   * Unahndled Error
   */
  if (errorCode === 500) {
    push('/error-500');
  }

  return Promise.reject(err);
};

export default {
  santizizeInterceptor,
  errorInterceptor
};

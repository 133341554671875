import { computed, readonly, ref } from 'vue';
import ApiService from '@/core/services/ApiService';

export interface User {
  username: string;
  firstName: string;
  lastName: string;
  company: string;
  roles: any;
}

/**
 * Global State
 */
const authenticated = ref<boolean>(false);
const user = ref<User>();
const authMode = ref<string>();

export function useAuth() {
  const setUser = (newUser) => (user.value = newUser);
  const setAuthenticated = (newAuthenticated) => (authenticated.value = newAuthenticated);
  const setAuthMode = (newAuthMode) => (authMode.value = newAuthMode);

  const login = async function (username, password) {
    await ApiService.post('login', { username, password });
    const res = await ApiService.get('users/me');
    setUser(res.data);
    setAuthenticated(true);
  };

  const authorize = async function () {
    const res = await ApiService.get('users/me');
    setUser(res.data);
    setAuthenticated(true);
  };

  const logout = async function () {
    await ApiService.post('logout', {});
    setUser({});
    setAuthenticated(false);
  };

  const register = async function (registerData) {
    await ApiService.post('/register', registerData);
  };

  const resetPassword = async function (resetPasswordData) {
    await ApiService.post('/reset-password', resetPasswordData);
  };

  const setNewPassword = async function (resetPasswordData) {
    await ApiService.post('/set-new-password', resetPasswordData);
  };

  const init = async function () {
    const res = await ApiService.get('/config');
    setAuthMode(res.data.authMode);
  };

  return {
    init,
    authMode: readonly(authMode),
    user: readonly(user),
    authenticated: readonly(authenticated),
    login,
    logout,
    register,
    authorize,
    resetPassword,
    setNewPassword
  };
}

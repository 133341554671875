import { createApp } from 'vue';
import App from './App.vue';

import router from './router';
import store from './store';
import ElementPlus from 'element-plus';
import i18n from '@/core/plugins/i18n';

//imports for app initialization
import ApiService from '@/core/services/ApiService';
import { initInlineSvg } from '@/core/plugins/inline-svg';
import { initVeeValidate } from '@/core/plugins/vee-validate';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import '@/core/plugins/prismjs';
import '@/core/plugins/sweetalert';
import '@/core/plugins/fontawesome';

const app = createApp(App);

app.component('font-awesome-icon', FontAwesomeIcon);
app.use(store);
app.use(router);
app.use(ElementPlus);

ApiService.init(app);
initInlineSvg(app);
initVeeValidate();

app.use(i18n);

app.mount('#app');
